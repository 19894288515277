import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button, Heading, Link, Text } from '@chakra-ui/react';
import decode from 'jwt-decode';
import get from 'lodash/get';

import WideLogo from '../svgs/WideLogo';
import { useCustomToast } from '../hooks/useCustomToast';
import SEO from '../components/seo';
import Input from '../components/Input';
import { useSpinner } from '../contexts/SpinnerContext';
import { Section, Wrapper, FormContainer, ButtonWrapper } from '../styles/new-password';

const CREATE_CUSTOMER = gql`
  mutation CREATE_CUSTOMER($data: InternalCustomerCreateInput!) {
    createInternalCustomer(data: $data) {
      id
    }
  }
`;

const LeadFormSchema = Yup.object().shape({
  contactName: Yup.string().required('Du musst einen Namen angeben'),
  companyName: Yup.string().required('Du musst einen Firmennamen angeben'),
  email: Yup.string().email('Korrigiere Deine Angabe').required('Du musst eine Email angeben'),
  phone: Yup.string().required('Du musst eine Telefonnummer angeben'),
});

const LMCL = () => {
  const { toggleSpinner } = useSpinner();
  const [status, setStatus] = useState('FORM');
  const toast = useCustomToast();
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const handleSubmit = async ({ contactName, contactProfession, companyName, email, phone }) => {
    try {
      toggleSpinner(true);
      await createCustomer({
        variables: {
          data: {
            contactName,
            contactProfession,
            companyName,
            email,
            phoneNumbers: {
              set: [phone],
            },
            status: 'OPEN',
            leadStatus: 'WARM',
            source: 'LMCL',
          },
        },
      });
      setStatus('SUCCESS');
    } catch (error) {
      console.log(error);
      const errorText = get(error, 'graphQLErrors.0.message', '');
      toast({
        description: errorText,
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <>
      <SEO
        title="LMCL Kontaktformular"
        meta={[
          {
            property: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          contactName: '',
          contactProfession: '',
          companyName: '',
          email: '',
          phone: '',
        }}
        validationSchema={LeadFormSchema}>
        {() => {
          return (
            <Section>
              <WideLogo className="mb-8" />
              <Wrapper>
                <FormContainer>
                  {status === 'FORM' ? (
                    <>
                      <Input label="Name" name="contactName" />
                      <Input label="Jobbezeichnung" name="contactProfession" />
                      <Input label="Firmenname" name="companyName" />
                      <Input label="Email" name="email" />
                      <Input label="Telefonnummer" name="phone" />
                      <Text fontSize="sm" color="gray.500" mb="3">
                        Ich stimme der Verarbeitung und Speicherung meiner Daten gemäß der{' '}
                        <Link
                          color="blue.500"
                          href="/Datenschutzerklärung_16.03.2022.pdf"
                          target="_blank">
                          Datenschutzbestimmungen{' '}
                        </Link>
                        zu.
                      </Text>
                      <ButtonWrapper>
                        <Button width="100%" colorScheme="brand" type="submit">
                          Speichern
                        </Button>
                      </ButtonWrapper>
                    </>
                  ) : null}
                  {status === 'SUCCESS' ? (
                    <Box textAlign="center">
                      <Heading mb="4">🎉 Vielen Dank!</Heading>
                      <Text>Wir setzen uns bald mit Dir in Kontakt!</Text>
                    </Box>
                  ) : null}
                </FormContainer>
              </Wrapper>
            </Section>
          );
        }}
      </Formik>
    </>
  );
};

export default LMCL;
